import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { workSlug, aboutSlug, jobsSlug, newsSlug, cultureSlug, contactSlug, aboutKoSlug, jobsKoSlug, contactKoSlug } from '../actions'

import { Link } from 'gatsby'
import { bool } from 'prop-types';
import Search from '../svg/Search'

const NavItems = ({ open }) => {
    const langKey = useSelector(state => state.langKey.language)
    let urlBase
    const dispatch = useDispatch()

    const isHidden = open ? true : false;
    const navClassNames = isHidden ? 'is-open' : ''
    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        urlBase = langKey === "ko" ? "" : `/${langKey}`

        return (
            <>
            <nav className={navClassNames}>
                <ul>
                    <li className="nav-items nav-items--work"><Link to={`${urlBase}/work`} onClick={()=> dispatch(workSlug())} activeClassName="active" partiallyActive={true}>Work</Link></li>
                    <li className="nav-items nav-items--about"><Link to={`${urlBase}/about-us`} onClick={()=> dispatch(aboutKoSlug())} activeClassName="active">About</Link></li>
                    <li className="nav-items nav-items--jobs"><Link to={`${urlBase}/job`} onClick={()=> dispatch(jobsKoSlug())}  activeClassName="active">Jobs</Link></li>
                    <li className="nav-items nav-items--news"><Link to={`${urlBase}/news`} onClick={()=> dispatch(newsSlug())}  activeClassName="active" partiallyActive={true}>News</Link></li>
                    <li className="nav-items nav-items--blog"><Link to={`${urlBase}/culture`} onClick={()=> dispatch(cultureSlug())}  activeClassName="active" partiallyActive={true}>Culture</Link></li>
                    <li className="nav-items nav-items--contact"><Link to={`${urlBase}/contact-us`} onClick={()=> dispatch(contactKoSlug())}  activeClassName="active">Contact</Link></li>
                    <li className="nav-items nav-items--search"><Link to={`/search`} activeClassName="active"><Search /></Link></li>
                </ul>
            </nav>
            </>
        )
    }
    else{
        urlBase = langKey === "ja" ? "" : `/${langKey}`

        return (
            <>
            <nav className={navClassNames}>
                <ul>
                    <li className="nav-items nav-items--work"><Link to={`${urlBase}/work`} onClick={()=> dispatch(workSlug())} activeClassName="active" partiallyActive={true}>Work</Link></li>
                    <li className="nav-items nav-items--about"><Link to={`${urlBase}/about`} onClick={()=> dispatch(aboutSlug())} activeClassName="active">About</Link></li>
                    <li className="nav-items nav-items--jobs"><Link to={`${urlBase}/jobs`} onClick={()=> dispatch(jobsSlug())}  activeClassName="active">Jobs</Link></li>
                    {/* <li className="nav-items nav-items--news"><Link to={`${urlBase}/news`} onClick={()=> dispatch(newsSlug())}  activeClassName="active" partiallyActive={true}>News</Link></li> */}
                    <li className="nav-items nav-items--news"><a href="https://www.instagram.com/wktokyo/" target="_blank" activeClassName="active">News</a></li>
                    <li className="nav-items nav-items--blog"><Link to={`${urlBase}/culture`} onClick={()=> dispatch(cultureSlug())}  activeClassName="active" partiallyActive={true}>Culture</Link></li>
                    <li className="nav-items nav-items--contact"><Link to={`${urlBase}/contact`} onClick={()=> dispatch(contactSlug())}  activeClassName="active">Contact</Link></li>
                    <li className="nav-items nav-items--search"><Link to={`/search`} activeClassName="active"><Search /></Link></li>
                </ul>
            </nav>
            </>
        )
    }
    
    
}

NavItems.propTypes = {
    open: bool.isRequired,
}

export default NavItems